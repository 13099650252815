import { z } from 'zod'

import { ENV_TAG } from '../constants'

export const DATADOG_RUM_SERVICE_NAME = 'www.qogita.com'

export const DATADOG_RUM_APPLICATION_ID = z
  .string()
  .default('')
  .refine((value) => (ENV_TAG === 'prod' ? Boolean(value) : true), {
    message:
      'NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID is required in production environment',
  })
  .parse(process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID)

export const DATADOG_CLIENT_TOKEN = z
  .string()
  .default('')
  .refine((value) => (ENV_TAG === 'prod' ? Boolean(value) : true), {
    message:
      'NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN is required in production environment',
  })
  .parse(process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN)

export const IS_DATADOG_RUM_ENABLED = Boolean(
  DATADOG_RUM_APPLICATION_ID && DATADOG_CLIENT_TOKEN,
)
export const IS_DATADOG_BROWSER_LOGS_ENABLED = Boolean(DATADOG_CLIENT_TOKEN)

export const DATADOG_SITE = 'datadoghq.eu'

/**
 * Sample rate for every anonymous users
 */
export const DATADOG_ANONYMOUS_SESSION_REPLAY_SAMPLE_RATE = 5
