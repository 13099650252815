import { datadogLogs, HandlerType } from '@datadog/browser-logs'

import { ENV_TAG } from '../constants'
import {
  DATADOG_CLIENT_TOKEN,
  DATADOG_RUM_SERVICE_NAME,
  DATADOG_SITE,
  IS_DATADOG_BROWSER_LOGS_ENABLED,
} from './constants'

export function initDatadogBrowserLogger({
  isDatadogAccepted,
}: {
  isDatadogAccepted: boolean
}) {
  if (!IS_DATADOG_BROWSER_LOGS_ENABLED) return

  const trackingConsent = isDatadogAccepted ? 'granted' : 'not-granted'

  if (!datadogLogs.getInitConfiguration()) {
    datadogLogs.init({
      clientToken: DATADOG_CLIENT_TOKEN,
      site: DATADOG_SITE,
      service: DATADOG_RUM_SERVICE_NAME,
      version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || '',
      env: ENV_TAG,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      trackingConsent,
    })

    datadogLogs.logger.setHandler(
      process.env.NODE_ENV === 'production'
        ? HandlerType.http
        : HandlerType.console,
    )
  }

  datadogLogs.setTrackingConsent(trackingConsent)
}

export const clientLogger = datadogLogs.logger

export function logClientWarning(
  error: Error,
  {
    message,
    ...context
  }: {
    message?: string
    [key: string]: unknown
  } = {},
) {
  clientLogger.warn(message || error.message, context, error)
}

export function logClientError(
  error: Error,
  {
    message,
    ...context
  }: {
    message?: string
    [key: string]: unknown
  } = {},
) {
  clientLogger.error(message || error.message, context, error)
}

export function logErrorBoundaryError(
  error: Error & { digest?: string; fingerprint?: string; details?: unknown },
) {
  // Override custom fingerprints to ensure the error creates
  // a new issue related to the uncaught error event.
  const fingerprint =
    error.fingerprint && typeof error.fingerprint === 'string'
      ? {
          fingerprint: `${error.fingerprint} unhandled`,
        }
      : {}

  // Extract custom error details to include in the log
  const details =
    error.details && typeof error.details === 'object' ? error.details : {}

  clientLogger.error(
    `ErrorBoundary: ${error.message}`,
    {
      error: {
        ...details,
        ...fingerprint,
        is_error_boundary: true,
        error_source: error.digest ? 'server' : 'client',
        digest: error.digest,
      },
    },
    error,
  )
}

export const createClientDebugLogger = (name: `__${string}`) => {
  const logger = datadogLogs.createLogger(name, {
    level: 'debug',
    handler: HandlerType.console,
  })

  function isEnabled(): boolean {
    if (typeof window === 'undefined') return false
    // enable/disable the logger with:
    // localStorage.setItem(name, "true")
    // localStorage.removeItem(name)
    return localStorage.getItem(name) === 'true'
  }

  return (message: string, context?: object) => {
    if (!isEnabled()) return
    logger.debug(`[${name}]: ${message}`, context)
  }
}
