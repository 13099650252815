'use client'

import '../global.css'

import { Button } from '@qogita/ui/button'
import type { Metadata } from 'next'
import { useEffect } from 'react'
import { NextAnchor } from 'src/components/next-anchor'
import { logErrorBoundaryError } from 'src/core/datadog-browser/logger'

export const metadata: Metadata = {
  title: 'Something went wrong',
  description:
    'We are working to resolve this. Please try again or contact us if it persists.',
}

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    logErrorBoundaryError(error)
  }, [error])

  return (
    // global-error must include html and body tags
    <html lang="en">
      <body>
        <main className="flex min-h-screen flex-col items-center justify-center gap-4 bg-gray-50 p-2">
          <h1 className="text-2xl sm:text-3xl">Something went wrong</h1>
          <p>
            We are working to resolve this. Please{' '}
            <Button color="primary" size="inline" onClick={() => reset()}>
              try again
            </Button>{' '}
            or{' '}
            <NextAnchor
              color="primary"
              size="inline"
              href="/contact/"
              hideProgressBar
            >
              contact us
            </NextAnchor>{' '}
            if it persists.
          </p>
        </main>
      </body>
    </html>
  )
}
