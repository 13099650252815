'use client'

import { cn } from '@qogita/ui/utils/cn'
import {
  AnimatePresence,
  motion,
  useMotionTemplate,
  useMotionValue,
  useSpring,
} from 'motion/react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import {
  ComponentProps,
  createContext,
  startTransition,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useInterval } from 'src/hooks/use-interval'

function useDefaultProgressBarValue(): ReturnType<typeof useProgress> {
  const defaultValue = useMotionValue(0)

  return {
    state: 'initial',
    value: defaultValue,
    start: () => void 0,
    done: () => void 0,
    reset: () => void 0,
  }
}

const ProgressBarContext = createContext<ReturnType<typeof useProgress> | null>(
  null,
)

export function useProgressBar() {
  const defaultValue = useDefaultProgressBarValue()
  const progress = useContext(ProgressBarContext) ?? defaultValue
  return progress

  // While we still have the pages router, we don't want to throw if a ProgressBarLink is rendered outside of a ProgressBar.
  // As that context isn't available in the pages router.
  // It just means the link won't show the progress bar
  // Once the pages router is removed, we can remove this check and throw if the context is not available.

  // if (!progress) {
  //   throw new Error('Must be used within a ProgressBar')
  // }
  // return progress
}

/**
 * Renders a progress bar that will show if any `<ProgressBarLink>` inside it is navigating.
 *
 * @see https://buildui.com/posts/global-progress-in-nextjs
 */
export function ProgressBar({
  children,
  className,
  'aria-live': ariaLive = 'polite',
  ...props
}: Omit<ComponentProps<'div'>, 'aria-busy' | 'role'>) {
  const progress = useProgress()
  const width = useMotionTemplate`${progress.value}%`

  return (
    <ProgressBarContext.Provider value={progress}>
      <AnimatePresence onExitComplete={progress.reset}>
        {progress.state === 'complete' ? null : (
          <motion.div exit={{ opacity: 0 }}>
            <div
              className={cn(
                'border-b',
                {
                  'border-b-gray-50 bg-gray-50': progress.state !== 'initial',
                  'border-transparent bg-transparent':
                    progress.state === 'initial',
                },
                className,
              )}
              role="progressbar"
              aria-live={ariaLive}
              aria-busy={progress.state === 'in-progress'}
              {...props}
            >
              <motion.div style={{ width }} className="bg-primary-700 h-full" />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </ProgressBarContext.Provider>
  )
}

/**
 * A link that shows a progress bar while navigating
 *
 * @see https://buildui.com/posts/global-progress-in-nextjs
 */
export function ProgressBarLink({
  href,
  children,
  onClick,
  ...props
}: ComponentProps<typeof Link>) {
  const progress = useProgressBar()
  const router = useRouter()

  return (
    <Link
      href={href}
      {...props}
      onClick={(event) => {
        event.preventDefault()
        progress.start()

        startTransition(() => {
          onClick?.(event)
          router.push(href.toString())
          progress.done()
        })
      }}
    >
      {children}
    </Link>
  )
}

/**
 * A hook that manages a progress bar animation with spring physics.
 * Progress will continuously increase while in progress state but never reach 100%
 * until explicitly completed. Provides controls to start, complete, and reset the animation.
 *
 * @see https://buildui.com/posts/global-progress-in-nextjs
 */
function useProgress() {
  const [state, setState] = useState<
    'initial' | 'in-progress' | 'completing' | 'complete'
  >('initial')

  const value = useSpring(0, {
    damping: 25,
    mass: 0.5,
    stiffness: 300,
    restDelta: 0.1,
  })
  useInterval(
    () => {
      // If we start progress but the bar is currently complete, reset it first.
      if (value.get() === 100) {
        value.jump(0)
      }

      const current = value.get()

      /** The amount of progress to add to the bar. */
      let delta: number
      if (current === 0) {
        delta = 15
      } else if (current < 50) {
        delta = random(1, 10)
      } else {
        delta = random(1, 5)
      }

      value.set(Math.min(current + delta, 99))
    },
    state === 'in-progress' ? 750 : null,
  )

  useEffect(() => {
    if (state === 'initial') {
      value.jump(0)
    } else if (state === 'completing') {
      value.set(100)
    }

    return value.on('change', (latest) => {
      if (latest === 100) {
        setState('complete')
      }
    })
  }, [value, state])

  function reset() {
    setState('initial')
  }

  function start() {
    setState('in-progress')
  }

  function done() {
    setState((state) =>
      state === 'initial' || state === 'in-progress' ? 'completing' : state,
    )
  }

  return { state, value, start, done, reset }
}

function random(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
